import imagesLoaded from 'imagesloaded';
import {bus} from './main'

export default {
	methods: {
	    loadAllImages() {
	        let images = document.getElementsByTagName('img')        
	        imagesLoaded(images, function(instance) {            
	            bus.$emit('show_loading', false);                          
	        });
	    },
	    detectImagesLoad() {
	        let images = document.getElementsByTagName('img')        
	        return new Promise((resolve, reject) => {
	        	imagesLoaded(images, function(instance) {            
	        	    resolve('loaded')
	        	});	        	
	        })
	    }	    
	}	
}